<template>

  <div>

    <vue-element-loading
      style="min-height: 500px;"
      :active="carregamentoApi"
      spinner="bar-fade-scale"
      text="Carregando Campanha"
    />

    <section
      id="knowledge-base-content"
      class="pb-5"
    >

      <b-row>

        <b-col
          xl="4"
          cols="12"
        >

          <b-img 
            thumbnail
            fluid 
            class="mb-2"
            :src="campanha.arquivo"
          ></b-img>

        </b-col>

        <b-col
          xl="8"
          cols="12"
        >

          <b-card
            no-body
          >
            <b-card-body 
              class="ecommerce-card mt-1 ml-1"
            >

              <b-badge
                variant="secondary"
              >
              Válida de {{ campanha.inicio }} até {{ campanha.fim }} 
              </b-badge>
              <h3 class="mt-1">{{ campanha.nome }}</h3>
              <p>{{ campanha.descricao }}</p>
            
            </b-card-body>
            
            <!-- Action Buttons -->
            <!-- <div class="item-options text-center">
              <b-button
                variant="primary"
                class="btn-cart move-cart"
                style="border-radius: 0px 0px 8px 8px !important;width: 100%;"
                @click="modalParticipar"
              >
                <span>Ouvir Hits</span>
                <b-icon
                  icon="arrow-right-short"
                  class="mr-50"
                />
              </b-button>
            </div> -->

          </b-card>

        </b-col>
        

      </b-row>

      <b-row>
        <b-col
          xl="12"
        >
          <hit-comprador-lista
            v-if="!carregamentoApi"
            :campanha-id="campanha.id"
          />
          
        </b-col>
      </b-row>

    </section>


  </div>
</template>

<script>
import router from '@/router'
import {
  BSpinner, BFormGroup, BAlert, BModal, BContainer, BRow, BCol, BCard, BCardTitle, BCardHeader, BCardBody, BForm, BFormInvalidFeedback, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, IconsPlugin, BBadge, BButton, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem, BImg,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

import useJwt from '@/auth/jwt/useJwt'

// CARREGANDO DE PÁGINA PARA API
import VueElementLoading from 'vue-element-loading'

import moment from 'moment'

import axios from 'axios'

import vSelect from 'vue-select'

import { required, email } from '@validations'

import HitCompradorLista from '../hits/HitCompradorLista.vue'

localize({
  en: {
    messages: {
      // generic rule messages...
    },
    fields: {
      hitId: {
        required: 'O nome do hit é obrigatório.',
      },
    },
  },
})

export default {
  components: {
    BSpinner,
    BFormGroup,
    BAlert,
    BModal,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BCardText,
    BForm,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    IconsPlugin,
    BFormInput,
    BBadge,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    VueElementLoading,
    moment,
    BImg,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    HitCompradorLista,

  },
  data() {
    return {

      campanha: {},
      carregamentoApi: false,
      hitsCompositor: [],
      hitSelecionado: null,
      respostaApi: null,
      minLetrasPesquisa: 3,
      hitsParticipantes: [],

    }
  },
  computed: {

    // this.hitsLista();

  },
  created() {
    this.campanhaId()
  },

  methods: {

    // MODAL NEGOCIAR

    campanhaId() {
      this.carregamentoApi = true

      useJwt.campanhaCompradorId({
        id: router.currentRoute.params.id,
      })
      .then(response => {
        //console.log(response.data)

        this.campanha = response.data
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.carregamentoApi = false
      })
    },


  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/pages/page-knowledge-base.scss';
  @import "@core/scss/base/pages/app-ecommerce.scss";


  .card-campanha .card-title{
    font-size: 14px;
  }

  .card-campanha .card-subtitle {
    font-size: 12px;
  }

  </style>
